@import 'src/app/styles/mixins/mixins.scss';

$default-profile-margin: 48px;

.no-side-border-xs {
  @include media-breakpoint-down(xs) {
    border-right-width: 0 !important;
    border-left-width: 0 !important;
  }
}

.row-on-xs {
  @include media-breakpoint-down(xs) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.tab-content {
  margin: $default-profile-margin;

  @include media-breakpoint-down(sm) {
    margin: $default-profile-margin / 2;
  }
}

.col-right {
  @include media-breakpoint-up(md) {
    padding-left: 33px
  }
}
