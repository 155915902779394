@import 'colors_theme.scss';

.title {
  font-family: 'Oswald', 'Arial Narrow', Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
}

.title-lg {
  @extend .title;
  font-size: 36px;
  line-height: 52px;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
    line-height: 28px;
  }
}

.title-md {
  @extend .title;
  font-size: 20px;
  line-height: 32px;
}

.title-section {
  @extend .title;
  font-size: 26px;
  line-height: 40px;

  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 28px;
  }

  &:not(.no-bottom-line) {
    padding-bottom: 11px;
    margin-bottom: 24px;
    border-bottom: 1px solid $gray-200;
  }

  &.small-padding-bottom {
    margin-bottom: 12px;
  }
}

.subtitle {
  font-size: 20px;
  line-height: 32px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 20px;
    line-height: normal;
  }
}

.label-input {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
}

.link {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-decoration-line: underline !important;

  @include media-breakpoint-down(sm) {
    font-size: 15px;
  }
}

.font-weight-medium {
  font-weight: 500;
}
