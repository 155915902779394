.gm-style-iw {
  text-align: center;

  + div {
    visibility: hidden;
  }

  .agm-info-window-content {
    margin: 7px 0;
  }
}
