@import 'src/app/styles/variables/_breakpoints.scss';

$swiper-arrow-size: 32px;
.swiper-arrow-container {
  &.outside-container {
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-only(md) {
      max-width: container-max-width(md) + 96px;
    }
    @include media-breakpoint-only(lg) {
      max-width: container-max-width(lg) + 96px;
    }
    @include media-breakpoint-only(xl) {
      max-width: container-max-width(xl) + 96px;
    }
  }

  .swiper-arrow {
    height: $swiper-arrow-size;
    width: $swiper-arrow-size;

    &.swiper-button-prev {
      left: 0;
      background-image: url('/assets/icons/ic-caret-left.svg');

      &.invert {
        background-image: url('/assets/icons/ic-caret-left-light.svg');
      }
    }
    &.swiper-button-next {
      right:0;
      background-image: url('/assets/icons/ic-caret-right.svg');

      &.invert {
        background-image: url('/assets/icons/ic-caret-right-light.svg');
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    margin-top: -16px;
    background-size: cover;
  }

  @include media-breakpoint-down(xs) {
    .swiper-arrow.swiper-button-prev {
      margin-left: - $swiper-arrow-size/2;
    }
  }
}

.swiper-pagination {
  height: 12px;
  width: 100%;

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 4px;
    display: inline-block;
    border-radius: 100%;
    background-color: $secondary;
    border: 1px solid $gray-600;
    opacity: .5;
    top: -6px;
    position: relative;
  }
  .swiper-pagination-bullet-dark {
    background-color: $dark;
    opacity: .8;
  }
  .swiper-pagination-bullet-light {
    background-color: $light;
    opacity: .8;
  }
}
