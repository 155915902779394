@import 'src/app/styles/mixins/mixins.scss';

$button-height: 56px;
$button-height-xs: 48px;

.btn {
  @extend .title;
  height: $button-height;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  padding: 12px 24px;

  @include media-breakpoint-down(xs) {
    height: 48px;
    padding: 8px 24px;
  }
}

.btn-primary {
  color: $white;
  &:hover {
    color: $white;
  }
}

.btn-outline-primary {
  border: 2px solid;
  &:hover {
    color: white;
  }
}

.btn-confirm {
  width: 276px;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}
