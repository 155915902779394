.gym-label {
  font-family: 'Oswald', 'Arial Narrow', Arial, sans-serif;
  text-transform: uppercase;
}

.gym-services {
  @extend .gym-label;
  font-size: 16px;
  line-height: 36px;
}

.gym-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
}
