.header-container {

  .header-image {
    height: 100%;
    filter: brightness(60%);
    object-fit: cover;
  }

  .header-text {
    position: absolute;
    top: 0;
    height: 100%;

    // Usado para manter o container no fundo da div de .header-text
    display: flex;
    flex-direction: column-reverse;

    h1.title {
      font-size: 64px;
      line-height: 96px;

      @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    .call-to-action {
      margin-bottom: 128px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 52px;
      }
    }

    // Usado para corrigir o espaçamento do subtítulo quando não há botão de link
    .invisible-div {
      height: $button-height;

      @include media-breakpoint-down(sm) {
        height: $button-height-xs;
      }
    }

  }
}

.swiper-arrow-container {
  top: 0;
  height: 100%;
}
