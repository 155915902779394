/* You can add global styles to this file, and also import other style files */

@import './app/styles/functions/functions.scss';
@import './app/styles/variables/variables.scss';
@import './app/styles/mixins/mixins.scss';

$enable-rounded: false;
@import "~bootstrap/scss/bootstrap";
@import '~ngx-toastr/toastr-bs4-alert.scss';

@import '~swiper/dist/css/swiper.min.css';

@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500');
// @import url('https://fonts.googleapis.com/css?family=Roboto:400,500');
@import './app/styles/roboto';


body {
  position: relative;
  overflow-y: scroll; /* This prevents content stagger when page loads */
  font-family: 'Roboto', sans-serif;
  color: $gray-700;

  @media print {
    background-color: $white;
  }
}

.main-wrapper {
  margin-top: $default-section-margin-top;

  &.sm {
    margin-top: 2rem;
  }

  @include media-breakpoint-down(sm) {
    margin-top: $default-section-margin-top-small + 5px;
  }
}

.light-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, .04)
}

.centralize-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.close:focus {
  outline: 0;
}

.card {
  @extend .light-shadow;
  @media print {
    border: 0;
  }
}

@import './app/styles/button.scss';
@import './app/styles/card.scss';
@import './app/styles/dropdown.scss';
@import './app/styles/header.scss';
@import './app/styles/form.scss';
@import './app/styles/footer.scss';
@import './app/styles/gym.scss';
@import './app/styles/modal.scss';
@import './app/styles/text.scss';
@import './app/styles/tooltip.scss';

@import './app/styles/libs/agm.scss';
@import './app/styles/libs/swiper.scss';
@import './app/styles/libs/select.scss';


@media all and (-ms-high-contrast:none) {
  @import './app/styles/styles-ie.scss';
}
